
import Vue from 'vue'
import { mapGetters, mapActions } from 'vuex'
import BoosterPicture from '../../node_modules/nuxt-booster/runtime/components/BoosterPicture.vue'
export default Vue.extend({
  name: 'Opportunity',
  components: {
    BoosterPicture
  },
  props: {
    opportunity: {
      type: Object,
      default: () => {}
    },
    index: {
      type: String,
      default: '-1'
    },
    fromRadar: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      $gtm: null,
      section: 'description'
    }
  },
  computed: {
    ...mapGetters({
      isMobile: 'site/isMobile'
    }),
    isRecentOpportunity() {
      return this.$dayjs().diff(this.$dayjs(this.opportunity.ts), 'day') <= 7
    },
    formattedDescription() {
      const description = this.opportunity.description
      let cleanDescription = description
      cleanDescription = cleanDescription.replace(/<\/?[^>]+(>|$)/g, '')
      // Rimuovi 'Descrizione:'
      if (cleanDescription.includes('Descrizione:')) {
        cleanDescription = cleanDescription.replace('Descrizione:', '')
      }

      // Taglia tutto ciò che viene dopo 'Requisiti'
      if (cleanDescription.includes('Requisiti')) {
        cleanDescription = cleanDescription.split('Requisiti')[0]
      }

      return cleanDescription.trim()
    },
    getTags() {
      let tags = []
      // const max = this.fromRadar ? 9 : 3
      if (typeof this.opportunity.contract_type !== 'number') {
        // Contratto
        if (this.opportunity.contract_type) {
          tags.push(this.opportunity.contract_type)
        }
        // RAL
        if (this.opportunity.ral_max && this.opportunity.ral_max > 0) {
          tags.push(this.opportunity.ral_max + ' ' + this.opportunity.currency)
        }
        // Remote working
        if (this.opportunity.remote_working) {
          tags.push(this.opportunity.remote_working)
        }
      } else {
        // dalla radar-campaign-by-cid

        // Contratto
        if (Number(this.opportunity.contract_type) !== 0 && this.opportunity.contracttypestring && this.opportunity.contracttypestring[0]) {
          tags.push(this.opportunity.contracttypestring[0])
        }
        // RAL
        if (this.opportunity.ral_max && this.opportunity.ral_max > 0) {
          tags.push(this.opportunity.ral_max + ' ' + this.opportunity.currency)
        }

        // Remote
        if (Number(this.opportunity.id_correlation_remote_working) !== 0 && this.opportunity.remoteworkingstring && this.opportunity.remoteworkingstring[0]) {
          tags.push(this.opportunity.remoteworkingstring[0])
        }
      }
      // Settore
      if (typeof this.opportunity.macrocategory !== 'number' && this.opportunity.macrocategory) {
        tags.push(this.opportunity.macrocategory)
      } else if (typeof this.opportunity.macrocategory === 'number' && this.opportunity.macrocategory > 0) {
        tags.push(this.opportunity.macrocategorystring)
      } else if (this.opportunity.umanacategory1) {
        tags.push(this.opportunity.umanacategory1)
      }

      tags = tags.slice(0, 3)
      return tags.map((tag) => {
        if (typeof tag === 'string') {
          return tag.charAt(0).toUpperCase() + tag.slice(1)
        }
        return tag
      })
    },

    cities() {
      // Controlla se 'city' è una stringa e separa le città se contiene '|'
      return this.opportunity.city
        ? this.opportunity.city.split('|')
        : []
    },
    getCities() {
      const cities = this.opportunity.city
        ? this.opportunity.city.split('|')
        : []
      const splice = this.isMobile ? 3 : 4
      if (cities.length > 1) {
        return cities.map((city, index) => {
          const isLastInGroup = (index + 1) % splice === 0 && index + 1 !== cities.length
          return isLastInGroup ? `${city},\n` : city
        }).join(', ').replace(/,\n,/g, ',\n') // Rimuove eventuali virgole doppie
      }
      return ''
    }
  },
  methods: {
    ...mapActions({
      setCampaign: 'campaign/setCampaign'
    }),
    getMedia(url, date) {
      const parts = url.split('/')
      const updatedUrl = parts.slice(4).join('/')
      return `/${updatedUrl}?updateTs=${date}`
    },
    openLink(link) {
      // Apri il link in una nuova scheda del browser
      const newWindow = window.open(link, '_blank')
      if (newWindow) {
        newWindow.opener = null // Evita che la finestra aperta possa accedere all'opener
        newWindow.focus() // Porta la nuova scheda in primo piano
      } else {
        // Gestisci il blocco dei popup
        alert('I popup sono bloccati dal tuo browser. Abilita i popup per aprire il link.')
      }
    },
    trackAndRedirect() {
      if (!this.fromRadar) {
        this.$gtm.push({
          event: 'click_job_application',
          event_data: {
            company_name: this.opportunity?.company?.co_name,
            job_application_name: this.opportunity.title,
            job_application_place: this.opportunity.city,
            is_stage: this.opportunity.id_contract_type === 8,
            verified: this.opportunity.verified,
            orp: this.opportunity.p2p,
            orp_value: this.opportunity.amountunlock,
            page_url: this.$router.currentRoute.fullPath,
            page_title: document ? document?.title : 'CVing'
          }
        })
        this.$router.push(this.localePath({
          name: 'radar-city-slug',
          params: { city: this.opportunity?.cityslug || 'no-city', slug: this.opportunity?.slug },
          query: { ...this.$route?.query, id: this.opportunity.cid, ea: '0' }
        }))
      }
    }
  }
})
